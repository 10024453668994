import * as React from "react";
import Header from "./FluentMain/Header";
import { makeStyles, Spinner, Image, Button,  FluentProvider, tokens } from "@fluentui/react-components";
import type {BrandVariants, Theme } from "@fluentui/react-components";
// import { ThemeProvider, createTheme, ITheme } from "@fluentui/react";
// import { Stack } from "@fluentui/react/lib/Stack";
import { Ribbon24Regular, LockOpen24Regular, DesignIdeas24Regular } from "@fluentui/react-icons";
import { useState, useContext, useEffect, Suspense, startTransition } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./firebase-config"
import {AuthContext} from './data/DataProvider';

// import TextInsertion from "./TextInsertion";
const Sidebar = React.lazy(() => import('./FluentMain/Sidebar'));
const Auth = React.lazy(() => import('./FluentMain/Auth'));
const AdminCenter = React.lazy(() => import('./FluentMain/AdminCenter'));





interface AppProps {
  title: string;
  lightTheme: Theme;
  darkTheme: Theme;
}

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    backgroundColor: tokens.colorNeutralBackground4
  },
  image: {
    display: "flex",
    justifySelf: "center"
  },
  
});


  // 🌟 Themed Button Component

  const LazyAuth = () => (
    <Suspense fallback={<Spinner />}>
      <Auth />
    </Suspense>
  );


const App: React.FC<AppProps> = (props: AppProps) => {
    const { title, lightTheme, darkTheme } = props;

  const styles = useStyles();
  // The list items are static and won't change at runtime,
  // so this should be an ordinary const, not a part of state.
  const [render, setRender] = useState(false)
//   const [user, setUser] = useState<string | null>(null)

  const[pageToRender, setPageToRender] = useState("Home")

  const [currentTheme, setCurrentTheme] = useState<Theme>(lightTheme);

  const { user, userData, loading } = useContext(AuthContext);

  const toggleTheme = () => {
    if(currentTheme === lightTheme){
        setCurrentTheme(darkTheme)
    } else {
        setCurrentTheme(lightTheme);
    }
    
  };


const handleSwitchPage = (page: string) => {
    startTransition(() => {
    setPageToRender(page)
    })
}

if (loading) {
    console.log("App is waiting for Firebase auth...");
    return (
      <div className={styles.root}>
        <Spinner />
      </div>
    );
  }

  if (!user){
    return (
        <div>
          <div className="container login-container">
            <div className="row text-center">
              <div className="col-sm-6 center">
                <Image
                  width="55"
                  height="55"
                  src={"beebot2.png"}
                  alt={title}
                  className={styles.image}
                />
              </div>
            </div>
            <div className="row text-center">
              <div className="col-sm-6 center">
                <LazyAuth />
              </div>
            </div>
          </div>
        </div>
      );
  }
  
  if (user && !user.emailVerified) {
    return (
      <div className={styles.root}>
        <p className="verify-text">
          Please verify account via email and then refresh this page.
        </p>
      </div>
    );
  }
  
  
    return (
      <FluentProvider theme={currentTheme}>
        <div className={styles.root}>
          <Header
            logo="beebot2.png"
            title={props.title}
            message="Beebot"
            theme={currentTheme === lightTheme ? "Light Mode" : "Dark Mode"}
            onToggle={toggleTheme}
            onSwitchPage={handleSwitchPage}
          />
          {pageToRender === "Home" && (
            <Suspense fallback={<div>Loading...</div>}>
              <Sidebar
                currentTheme={currentTheme === lightTheme ? "Light Mode" : "Dark Mode"}
              />
            </Suspense>
          )}
  
          {pageToRender === "Admin" && <AdminCenter />}
        </div>
      </FluentProvider>
    );

};

export default App;
