import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import './style.css';
import App from './App';
// import { reportWebVitals } from './reportWebVitals';
import 'react-tooltip/dist/react-tooltip.css';
import { createDarkTheme, createLightTheme } from '@fluentui/react-components';
import type { BrandVariants, Theme } from '@fluentui/react-components';

import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import {AuthProvider}  from "./data/DataProvider"


const title = "Beemail Parser";

const rootElement: HTMLElement | null = document.getElementById("root");
const root = rootElement ? createRoot(rootElement) : undefined;

const myNewTheme: BrandVariants = { 
    10: "#030205",
      20: "#161621",
      30: "#21233B",
      40: "#292E51",
      50: "#303A68",
      60: "#354780",
      70: "#38549A",
      80: "#3762B6",
      90: "#4770C1",
      100: "#5C7FC7",
      110: "#6F8DCD",
      120: "#819CD3",
      130: "#93ABDA",
      140: "#A6B9E0",
      150: "#B8C9E7",
      160: "#CBD8EE"
    };
    
     const lightTheme: Theme = {
       ...createLightTheme(myNewTheme), 
    };
    
     const darkTheme: Theme = {
       ...createDarkTheme(myNewTheme), 
    };
    
    
     darkTheme.colorBrandForeground1 = myNewTheme[110];
     darkTheme.colorBrandForeground2 = myNewTheme[120];

root?.render(
<React.StrictMode >
    <AuthProvider > 
        <App lightTheme={lightTheme} darkTheme={darkTheme}  title="Beebot Suped" />
    </AuthProvider >
</React.StrictMode>
  
);

if ((module as any).hot) {
    (module as any).hot.accept("./App", () => {
      const NextApp = require("./App").default;
      root?.render(NextApp);
    });
  }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
